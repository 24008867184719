<template>
  <div>
    <v-form v-model="form">
      <v-card>
        <v-card-title>
          BO-CHROME
        </v-card-title>
        <v-card-text>
            <span class="text-h1 green--text" v-if="Tool === 0">Đang kiểm tra Tool</span>
            <span class="text-h1 red" v-if="Tool === 2">TOOL CHƯA ĐƯỢC BẬT !!!</span>
            <span class="text-h1 red" v-if="Tool === 3">KHÔNG THỂ TẢI TÀI NGUYÊN</span>
            <template v-if="Tool === 1">
              <div class="mx-2">
                <span class="mx-2 text-h6 green--text">Số lượng Profiles: {{Profiles.length}}</span>
                <v-btn class="mx-2" color="green" :disabled="Running" @click="doOpenTool">Bật Chrome Order</v-btn>
                <br />
                <v-row>
                  <v-col
                    cols="6">
                    <span class="text-h4 blue--text">Cấu hình</span>
                    <v-divider />
                    <v-row>
                      <v-col
                        cols="auto">
                        <v-select class="mx-2" v-model="configs.ProxyType"
                          :items="ProxyType"
                          item-value="value"
                          item-text="text"
                          label="Cấu hình Proxy"
                          @change="doUpdateProxyType"
                        />
                        <v-select class="mx-2" v-model="configs.ProxySelected" v-if="configs.ProxyType === 0"
                          :items="ProxyList"
                          item-value="value"
                          item-text="text"
                          label="Proxy List"
                          @change="doUpdateProxyList"
                        />
                        <v-text-field class="mx-2" v-model="configs.Port911" v-if="configs.ProxyType === 1"
                          label="911 Port"
                          @change="doUpdateProxy911"
                        />
                      </v-col>
                      <v-col
                          cols="auto">
                        <v-checkbox class="mx-2" v-model="configs.FakeUA"
                            label="Fake User-Agent"
                             @change="doUpdateFakeUserAgent"
                        />
                        <v-select class="mx-2" v-model="configs.SelectedUA" v-if="configs.FakeUA"
                            :items="ListUA"
                            item-value="value"
                            item-text="name"
                            label="List User-Agent"
                            @change="doUpdateFakeUserAgent"
                        />
                      </v-col>
                      <v-col
                          cols="auto">
                        <v-select class="mx-2" v-model="configs.FakeResolution"
                          :items="FakeResolution"
                          item-value="value"
                          item-text="text"
                          label="Fake Resolution"
                          @change="doUpdateFakeResolution"
                        />
                        <template  v-if="configs.FakeResolution === 1">
                          <v-text-field class="mx-2" v-model="configs.width"
                            label="Width"
                          />
                          <v-text-field class="mx-2" v-model="configs.height"
                            label="Height"
                          />
                        </template>
                      </v-col>
                      <v-col
                          cols="auto">
                        <v-autocomplete v-model="configs.tz"
                          label="Time Zone"
                          :items="timezone"
                        />
                        <v-text-field v-model="configs.locale"
                          label="Locale"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <span class="mx-2 text-h4 blue--text">Danh sách Profile</span>
                    <v-divider />
                    <Datatables
                        :headers="headers"
                        :items="itemsWithIndex"
                        :group="false"
                    >
                      <template #tools>
                        <v-col
                            cols="6"
                        >
                          <span>
                            <v-text-field v-model="NewProfile"
                              label="Tên Profile"
                            />
                            <v-btn
                                color="green"
                                @click="doCreateProfile()"
                            >
                              Tạo
                            </v-btn>
                          </span>
                        </v-col>
                      </template>
                    </Datatables>
                  </v-col>
                </v-row>
              </div>
              <br />
            </template>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
        <loading :status="loader" />
      </v-card>
    </v-form>
  </div>
</template>

<script>
import loading from '@/components/base/loading.vue';
import Datatables from '@/components/base/datatables.vue'
import ClientHelper from '@/helpers/chrome/chrome.js'
import headers from '@/config/headers/chrome.js';
import Controller from '@/config/controllers/chrome.js'
import '@/main.js';
import timezone from '@/json/tz.json';
const ClientService = new ClientHelper();
export default {
  name: "chrome",
  components:{
    loading,
    Datatables,
  },
  data () {
    return {
      ...headers,
      timezone,
      NewProfile:null,
      ProxyLink:undefined,
      Running:false,
      form:false,
      loader:false,
      downloader:false,
      configs:{
        FakeResolution:0,
        Port911:null,
        FakeUA:false,
        ProxyType: 0,
        SelectedUA: 0,
        ProxySelected: undefined,
        dir:undefined,
        width:1920,
        height:1040,
        i_width:1920,
        i_height:937,
        tz:"America/Los_Angeles",
        locale:"en_US",
        ua:undefined,
      },
      ListData:{},
      Tool:0,
      Profiles:[],
      ProxyType:[{value:0,text:"Proxy"},{value:1,text:"911"},{value:2,text:"VPN - NO PROXY"}],
      //{value:1,text:"Tự điều chỉnh"},
      FakeResolution:[{value:0,text:"Tắt"},{value:2,text:"iPAD"},{value:3,text:"iPhone"},{value:4,text:"MBP"}],
      checkRunning:undefined,
    }
  },
  computed:{
    ProxyList(){
      if(!this.ListData.proxy) return [];
      return this.ListData.proxy.map((item,index)=>{
        return {
          ...item,
          value:index,
          text:`${item.proxy_address}-${item.country_code}`
        }
      });
    },
    ListUA(){
      if(!this.ListData.ua) return [];
      return this.ListData.ua.map((item,index)=>{
        return {
          ...item,
          value:index,
        }
      });
    },
    itemsWithIndex(){
      return this.Profiles.map(
          (items, index) => {
            return {
              index: index + 1,
              Name: items,
              controller:this.controller(items)
            }
          }).filter(v=>!!v)
    }
  },
  methods: {
    doCheckToolOpened:ClientService.doCheckToolOpened,
    doGetData:ClientService.doGetData,
    doUpdateProxyList:ClientService.doUpdateProxyList,
    doUpdateProxy911:ClientService.doUpdateProxy911,
    doUpdateFakeUserAgent:ClientService.doUpdateFakeUserAgent,
    doUpdateFakeResolution:ClientService.doUpdateFakeResolution,
    doOpenTool:ClientService.doOpenTool,
    doCheckToolRunning:ClientService.doCheckToolRunning,
    doCreateProfile:ClientService.doCreateProfile,
    doDeleteProfile:ClientService.doDeleteProfile,
    doUpdateProxyType:ClientService.doUpdateProxyType,
    controller:Controller.Chrome,
  },
  mounted(){
    const Auto = [this.doCheckToolOpened(),this.doGetData()];
    this.loader = true;
    Promise.all(Auto).finally(()=>{
      this.loader = false;
      this.checkRunning = setInterval(this.doCheckToolRunning,1000);
    });
  },
  beforeDestroy(){
    if(this.checkRunning) clearInterval(this.checkRunning);
  }
}
</script>

<style scoped>

</style>