import ChromeService from '../../modules/chrome.js';
import { ThongBao } from '../'

import axios from 'axios';
export default class Chrome{
	doCheckToolOpened(){
		axios.get('http://localhost:2502/profiles',{timeout:2000}).catch(e=>{
			this.Tool = 2;
		}).then((resp)=>{
			const {data} = resp.data;
			this.Tool = 1;
			this.Profiles = data;
		});
	}
	async doDeleteProfile(profile){
		const xn = await ThongBao.XacNhan("Xác nhận xóa profile: "+profile.Name);
		if(xn){
			axios.get('http://localhost:2502/delete/'+profile.Name, {timeout: 2000}).then((resp)=>{
				const {success,error} = resp.data;
				if(success){
					this.NewProfile = null;
					ThongBao.Success("Đã xóa Profile");
					this.doCheckToolOpened();
				}
				if(error){
					const msg = error || "Không thể xóa Profile"
					ThongBao.Error(msg)
				}
			}).catch(e => {
				ThongBao.Error("Không thể xóa Profile")
			})
		}
	}
	doCreateProfile(){
		if(!this.NewProfile) return ThongBao.Error("Nhập tên Profile");
		axios.get('http://localhost:2502/create/'+this.NewProfile, {timeout: 2000}).then((resp)=>{
			const {success,error} = resp.data;
			if(success){
				this.NewProfile = null;
				ThongBao.Success("Đã tạo Profile");
				this.doCheckToolOpened();
			}
			if(error){
				const msg = error || "Không thể tạo Profile"
				ThongBao.Error(msg)
			}
		}).catch(e => {
			ThongBao.Error("Không thể tạo Profile")
		})
	}
	doCheckToolRunning(){
		axios.get('http://localhost:2502/status', {timeout: 2000}).then((resp)=>{
			const {status} = resp.data;
			const Status = (status) ? 'ON':'OFF'
			this.Running = status;
			this.Tool = 1;
		}).catch(e => {
			this.Running = false;
			this.Tool = 2;
		})
	}
	doUpdateProxyType(){
		const {ProxyType} = this.configs;
		if(ProxyType === 0){
			this.doUpdateProxyList();
		}
		if(ProxyType === 1){
			this.doUpdateProxy911();
		}
	}
	doOpenTool(profile){
		if(profile){
			this.configs['dir'] = profile.Name;
		}
		if(this.configs.ProxyType !== 2){
			const {proxy} = this.configs;
			if(!proxy) return ThongBao.Error("Chọn Proxy trước !!!");
		}
		this.configs['width'] = parseInt(this.configs['width'])
		this.configs['height'] = parseInt(this.configs['height'])
		this.configs['i_width'] = parseInt(this.configs['i_width'])
		this.configs['i_height'] = parseInt(this.configs['i_height'])
		this.loader = true;
		axios.post('http://localhost:2502/run',this.configs).finally(()=>{
			this.loader = false;
			this.Running = true;
		});
	}
	doGetData(){
		ChromeService.Chrome().then(resp=>{
			this.ListData = resp.data;
		}).catch(e=>{
			this.Tool = 3;
		})
	}
	doUpdateProxyList(){
		const {ProxySelected} = this.configs;
		const proxyData = this.ProxyList[ProxySelected];
		this.configs['proxy'] = `http://${proxyData.proxy_address}:${proxyData.ports.http}`
		this.configs['user'] = proxyData.username;
		this.configs['pass'] = proxyData.password;
	}
	doUpdateProxy911(){
		const {Port911} = this.configs;
		this.configs['proxy'] = `socks5://127.0.0.1:${Port911}`
		this.configs['user'] = undefined;
		this.configs['pass'] = undefined;
	}
	doUpdateFakeUserAgent(){
		const {FakeUA,SelectedUA} = this.configs;
		if(FakeUA === false){
			this.configs['ua'] = undefined;
			this.configs['SelectedUA'] = 0;
			return;
		}
		const uaData = this.ListUA[SelectedUA];
		if(uaData.name.indexOf('Mac') !== -1){
			this.configs.FakeResolution = 4;
		}else if(uaData.name.indexOf('iPhone') !== -1){
			this.configs.FakeResolution = 3;
		}else if(uaData.name.indexOf('iPad') !== -1){
			this.configs.FakeResolution = 2;
		}else{
			this.configs.FakeResolution = 0;
		}
		this.doUpdateFakeResolution();
		this.configs['ua'] = uaData.data;
		this.configs['mobile'] = uaData.mobile;
	}
	doUpdateFakeResolution(){
		const {FakeResolution} = this.configs;
		if(FakeResolution === 0 || FakeResolution === 1){
			this.configs['width'] = 1920;
			this.configs['height'] = 1040;
			this.configs['i_width'] = 1920;
			this.configs['i_height'] = 937;
			return;
		}
		if(FakeResolution === 2){
			this.configs['width'] = 764;
			this.configs['height'] = 1024;
			this.configs['i_width'] = 764;
			this.configs['i_height'] = 1024;
			return;
		}
		if(FakeResolution === 3){
			this.configs['width'] = 414;
			this.configs['height'] = 896;
			this.configs['i_width'] = 414;
			this.configs['i_height'] = 716;
			return;
		}
		if(FakeResolution === 4){
			this.configs['width'] = 1440;
			this.configs['height'] = 900;
			this.configs['i_width'] = 1440;
			this.configs['i_height'] = 820;
			return;
		}
	}
}
